:root {
  --selected: #4caf50;
  --grey-400: #bdbdbd;
  --error-main: #d32f2f;
  --warning-main: #ed6c02;
  --warning-light: #ff9800;
}
.data-grid-custom-asc::after {
  font-family: 'Font Awesome 6 Free';
  content: '\f062';
  font-weight: 900;
}
.data-grid-custom-desc::after {
  font-family: 'Font Awesome 6 Free';
  content: '\f063';
  font-weight: 900;
}
.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable
  .MuiDataGrid-iconButtonContainer {
  display: none;
}
.dropzone {
  text-align: center;
  border: 1px solid #e0e0e0;
  cursor: pointer;
}
.locker-list .MuiDataGrid-row:hover:not([class^='datagrid_row-disabled']) {
  cursor: pointer;
}
.selectBoxErrorField {
  width: 292px !important;
  margin-right: 14px !important;
  margin-left: 14px !important;
}
.color-legend {
  width: 32px;
  height: 32px;
  border: 1px solid rgb(224, 224, 224);
}
.locker-deleted {
  background-color: rgba(23, 23, 23, 0.1);
}
.locker-incomplete {
  background-color: rgba(237, 108, 2, 0.1);
}
.locker-complete {
  background-color: white;
}
.labelInput-wrapper {
  align-items: flex-start;
}
.labelInput-wrapper .MuiInputLabel-root {
  overflow: visible !important;
  white-space: break-spaces !important;
}
.leaflet-container {
  height: 400px;
}

.customDialog .MuiDialog-container.MuiDialog-scrollPaper {
  width: 100%;
}
.active-reservation-list .MuiDataGrid-row {
  border-left: 10px solid #fff;
}
.MuiDataGrid-row.unpaid-reservation {
  border-left: 10px solid var(--error-main);
}
.MuiDataGrid-row.unpaid-extension {
  border-left: 10px solid var(--warning-light);
}
.legend-unpaid-reservation {
  background-color: var(--error-main);
}
.legend-unpaid-extension {
  background-color: var(--warning-light);
}

.error {
  color: var(--error-main);
}

.uptime-level.average {
  background-color: var(--warning-main);
  color: #fff;
}
.uptime-level.above-average {
  background-color: var(--selected);
  color: #fff;
}
.uptime-level.bellow-average {
  background-color: var(--error-main);
  color: #fff;
}

.load-level.average {
  background-color: var(--warning-main);
  color: #fff;
}
.load-level.above-average {
  background-color: var(--selected);
  color: #fff;
}
.load-level.bellow-average {
  background-color: var(--error-main);
  color: #fff;
}

.full-screen-dialog .MuiPaper-root {
  background-color: transparent;
}
.lockers-map-container {
  background-color: white;
  margin: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
  background-color: rgba(253, 156, 115, 0.6);
}
.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  background-color: rgba(241, 128, 23, 0.6);
  text-align: center;
  border-radius: 15px;
  font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
.marker-cluster span {
  line-height: 30px;
}

.unauthenticated-header .language-selector {
  margin-right: 16px;
  margin-left: 0;
  margin-bottom: 0;
}
.unauthenticated-header
  .language-selector
  .Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #0000003b;
}

.unauthenticated-header
  .language-selector
  .MuiOutlinedInput-root.MuiInputBase-root {
  color: white;
}

.month-picker
  div[role='presentation']
  .PrivatePickersFadeTransitionGroup-root:nth-of-type(2) {
  display: none !important;
}

.row-cell-subtext {
  color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.locality-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 190px;
}

.leaflet-popup-close-button {
  display: none;
}

.close-map-popup-button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  text-align: center;
  width: 24px;
  height: 24px;
  color: #757575;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font: 16px/24px Tahoma, Verdana, sans-serif;
}
.close-map-popup-button:hover {
  color: #585858;
}

.drawer-type-card-header {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
}

.drawer-type-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 220px;
}

.drawer-box-container {
  width: 200px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: -6px -6px 8px -4px rgba(189, 189, 189, 0.75),
    6px -6px 8px -4px rgba(189, 189, 189, 0.75),
    6px 6px 8px -4px rgba(189, 189, 189, 0.75),
    6px 6px 8px -4px rgba(189, 189, 189, 0.75),
    0 0 8px rgba(189, 189, 189, 0.75);
}
.drawer-box-container.selected {
  background-color: var(--selected);
  color: white;
}

.selected-drawer-type {
  background-color: var(--selected) !important;
  transition: background-color 0.3s ease-in-out;
}
.selected-drawer-type .drawer-type-before {
  background-color: #388e3c !important;
  transition: background-color 0.3s ease-in-out;
}

.selected-drawer-type .drawer-type-after {
  background-color: #8bc34a !important;
  transition: background-color 0.3s ease-in-out;
}

.drawer-types-container {
  display: flex;
  row-gap: 100px;
  column-gap: 160px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 200px;
}
.drawer-dimensions-container {
  display: flex;
  gap: 5px;
  width: 100%;
}

.drawer-dimensions-container div {
  flex: 1;
}
.drawer-dimensions-container > :first-child {
  text-align: right;
}

.awb-ellipsis-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

.awb-cell {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

@media (min-width: 900px) {
  .labelInput-wrapper {
    align-items: center;
  }
}
@media (min-width: 1920px) {
  #root {
    max-width: 1920px;
    position: relative;
  }
  .main-header {
    max-width: 1740px;
    right: auto !important;
  }
  .spinner-container {
    position: absolute !important;
    min-height: 100vh;
  }
}
